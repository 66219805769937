import Header from "./Header2";
import React from "react";
import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import img from "../images/noImage.jpeg"
const axios = require('axios').default;

function HeaderPortfolio() {
  const [items, setItems] = useState('');
  const [categorys, setCategorys] = useState('');
  const [checkedItem, setCheckedItem] = useState("");
  const [cardData, setCardData] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    let url;
    if (checkedItem == "")
      url = `${process.env.REACT_APP_BASE_URL}portfolio?page=1&limit=12&sortBy=asc&status=1&categoryId`
    else
      url = `${process.env.REACT_APP_BASE_URL}portfolio?page=1&limit=12&sortBy=asc&status=1&categoryId=${checkedItem}`

    axios.get(url,
      {
        headers: {
          'Authorization': `Basic ${process.env.REACT_APP_BASIC_AUTH}`
        }
      }
    ).then(function (response) {
        setItems(response.data.data.docs);
      })
      .catch(function (error) {
      })
  }, [checkedItem]);

  
  useEffect(() => {
    let categoryUrl;
    categoryUrl = `${process.env.REACT_APP_BASE_URL}category?page=1&limit=12&sortBy=asc&status=1`;
   
      axios.get(categoryUrl,
        {
          headers: {
            'Authorization': `Basic ${process.env.REACT_APP_BASIC_AUTH}`
          }
        })
        .then(function (response) {
          setCategorys(response.data.data.docs);
        })
        .catch(function (error) {
        })
  },[]);

  const fetchComments = async (currentPage) => {
    let url, categoryUrl;

    categoryUrl = `${process.env.REACT_APP_BASE_URL}category?page=1&limit=12&sortBy=asc&status=1&categoryId`

    if (checkedItem == "")
      url = `${process.env.REACT_APP_BASE_URL}portfolio?page=${currentPage}&limit=12&sortBy=asc&status=1`
    else
      url = `${process.env.REACT_APP_BASE_URL}portfolio?page=${currentPage}&limit=12&sortBy=asc&status=1&categoryId=${checkedItem}`
    axios.get(url,
      {
        headers: {
          'Authorization': `Basic ${process.env.REACT_APP_BASIC_AUTH}`
        }
      })
      .then(function (response) {
        setItems(response.data.data.docs);
      })
      .catch(function (error) {
      });

    axios.get(categoryUrl,
      {
        headers: {
          'Authorization': `Basic ${process.env.REACT_APP_BASIC_AUTH}`
        }
      })
      .then(function (response) {
        setCategorys(response.data.data.docs);
      })
      .catch(function (error) {
      })
  };
  
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    const commentsFormServer = await fetchComments(currentPage);
    setItems(commentsFormServer);
  };

  async function setModalData(id) {
    let modalData = items.filter((elem) => {
      return elem.id === id;
    })
    setCardData(modalData);
  }
  return (
    <>
      <Header />
      {/* <!--Breadcrumb Area--> */}
      <section className="breadcrumb-area banner-3">
        <div className="text-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 v-center">
                <div className="bread-inner">
                  <div className="bread-menu">
                    <ul>
                      <li>
                        <a href="index-2.html">Home</a>
                      </li>
                      <li>
                        <a href="#">Portfolio</a>
                      </li>
                    </ul>
                  </div>
                  <div className="bread-title">
                    <h2>Our Projects</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Breadcrumb Area--> */}
      <section className="portfolio-page pad-tb">
        <div className="container">
          <div className="row justify-content-left">
            <div className="col-lg-6">
              <div className="common-heading pp">
                <span>Our Work</span>
                <h2>Portfolio</h2>
              </div>
            </div>
            <div className="v-center">
              <div className="filters">
                <ul className="filter-menu d-flex justify-content-center">
                  <li
                    onClick={() => {
                      setCheckedItem("");
                    }}
                    className={`mx-1 ${checkedItem == "" ? "is-checked" : ""
                      }`}
                  >
                    All
                  </li>

                  {categorys && categorys.map((category) => {
                    const {
                      id,
                      name
                    } = category;
                    
                    return (
                      <>
                        <li
                          onClick={() => {
                            setCheckedItem(`${id}`);
                          }}
                          className={`mx-1 ${checkedItem == `${id}` ? "is-checked" : ""
                            }`}
                        >
                          {name}
                        </li>
                        
                      </>
                    );
                  })
                }


                </ul>
              </div>
            </div>
          </div>
          <div className="row card-list">
            {items?.length > 0 ? items.map((elem) => {
              const {
                id,
                image,
                title,
                desc,
                category,
                slug,
                videoLink,
              } = elem;
              return (
                <>
                  <div
                    data-wow-delay="0.2s"
                    className=" wow fadeIn col-lg-4 col-sm-4 mt40 single-card-item website"
                  >
                    <div className="isotope_item up-hor">
                      <div className="item-image d-flex justify-content-center align-items-center bg-body " style={{ height: '300px' }} > 
                        {videoLink ? (<><iframe src={'https://www.youtube.com/embed/'+videoLink}
                          frameborder='0'
                          allow='autoplay; encrypted-media'
                          allowfullscreen
                          title='video'
                        /></>) : (<><a href="javascript:void(0)" onClick={() => setModalData(id)} data-bs-toggle="modal" data-bs-target="#modalform">
                          <img src={process.env.REACT_APP_BASE_IMAGE_URL + '/media/preview?filename=' + image} alt={image} style={{
                            maxHeight: '290px'
                          }} />{" "}
                        </a></>)}
                      </div>
                      <div className="item-info-div shdo  shadow-sm">
                        <h4>
                          <a href="javascript:void(0)" onClick={() => setModalData(id)} data-bs-toggle="modal" data-bs-target="#modalform">{title}</a>
                        </h4>
                        {/* <p>{desc}</p> */}
                      </div>
                    </div>
                  </div>
                </>
              );
            }) :
              <>
                <div className="d-flex justify-content-center flex-column" style={{ width: '450px' }} >
                  <img src={img} alt="" />
                  <h5 className="text-center">No Data Found</h5>
                </div>
              </>}
            <div class="popup-modals">
              <div class="modal" id="modalform" tabindex="-1" >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <div class="common-heading">
                        <h4 class="mt0 mb0">{cardData[0]?.title}</h4>
                      </div>
                      <button type="button" class="closes" data-bs-dismiss="modal">&times;</button>
                    </div>
                    <div class="modal-body">
                      <img src={process.env.REACT_APP_BASE_IMAGE_URL + '/media/preview?filename=' + cardData[0]?.image} alt={cardData[0]?.title} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-sm-4 mt40 single-card-item website">
              <div className="isotope_item up-hor">
                <div className="item-image">
                  <a href="#"><img src="images/portfolio/app-img2.jpg" alt="image" className="img-fluid" /> </a>
                </div>
                <div className="item-info-div shdo">
                  <h4><a href="#">Car Rental App</a></h4>
                  <p>Graphic, Print</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 mt40 single-card-item app graphic">
              <div className="isotope_item up-hor">
                <div className="item-image">
                  <a href="#"><img src="images/portfolio/app-img3.jpg" alt="image" className="img-fluid" /> </a>
                </div>
                <div className="item-info-div shdo">
                  <h4><a href="#">Event Management App</a></h4>
                  <p>Graphic, Print</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 mt40 single-card-item app">
              <div className="isotope_item up-hor">
                <div className="item-image">
                  <a href="#"><img src="images/portfolio/app-img4.jpg" alt="image" className="img-fluid" /> </a>
                </div>
                <div className="item-info-div shdo">
                  <h4><a href="#">Restaurant App</a></h4>
                  <p>iOs, Android</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 mt40 single-card-item graphic website">
              <div className="isotope_item up-hor">
                <div className="item-image">
                  <a href="#"><img src="images/portfolio/app-img5.jpg" alt="image" className="img-fluid" /> </a>
                </div>
                <div className="item-info-div shdo">
                  <h4><a href="#">Restaurant / Hotel App</a></h4>
                  <p>Graphic, Print</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 mt40 single-card-item graphic app">
              <div className="isotope_item up-hor">
                <div className="item-image">
                  <a href="#"><img src="images/portfolio/app-img6.jpg" alt="image" className="img-fluid" /> </a>
                </div>
                <div className="item-info-div shdo">
                  <h4><a href="#">Super Mart App</a></h4>
                  <p>Graphic, Print</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <div className="modal leadpopup" id="portfolioModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="innerleft">
                <div className="leadbtnclose">
                  {" "}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>
                <h1 className="text-center mb-3">Digital Arts</h1>
                <img src="/art/girl1.jpg" alt={checkedItem} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="pagination pagi1">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              previousLabel="< previous"
              pageRangeDisplayed={5}
              pageCount={12}
              renderOnZeroPageCount={null}
              marginPagesDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"list-style-none"}
              pageClassName={'page-item'}
              pageLinkClassName={''}
            />
            <ul>
              <li>
                <a href="#"> &lt; </a>
                <a className="is-active" href="#">{page}</a>
                <a href="#">{page + 1}</a>
                <a href="#">{page + 2}</a>
                <a href="#">{page + 3}</a>
                <a href="#">{page + 4}</a>
                <a href="#"> &gt; </a>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      <ReactPaginate
        previousLabel={"<<"}
        nextLabel={">>"}
        breakLabel={"..."}
        pageCount={10}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination d-flex justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />

    </>
  );
}

export default HeaderPortfolio;
