import React from "react";
import computers from "../images/icons/computers.svg";
import worker from "../images/icons/worker.svg";
import deal from '../images/icons/deal.svg';
import aboutCompany from '../images/about/about-company.jpg';


function StartAbout() {

    return (
        <div>
            <section class="about-sec-rpb pad-tb">
         <div class="container">
            <div class="row justify-content-center text-center">
               <div class="col-lg-10">
                  <div class="common-heading">
                     <span>The Breakthrough Your Company Needs</span>
                     <h1 class="mb30"><span class="text-second">Top-rated</span> Digital Agency</h1>
                     <p>We are a  <span class="text-radius text-light text-animation bg-b">branding platform </span> that operates on a subscription-based service that is entirely skilled and economical for everyone. We deliver the projects on time and earn the trust of a wider market. With our fixed monthly charge, you will be thrilled to work on endless brands for your solitary business or a unit.
                         {/* <span class="text-bold">Lorem Ipsum</span> is simply dummy text of the printing and typesetting industry. <span class="text-bold">Lorem Ipsum </span> is simply dummy text of the printing and typesetting industry. */}
                     </p>
                     <h3 class="mt30 mb30">Big Ideas, creative people, new technology.</h3>
                     <p> <span class="text-bold">Make it Simple</span>  makes sure that your compelling niche is secure. Throughout your branding journey, we provide reliable solutions. Our team is more precise about your specification. </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
            
        </div>
        );
}

export default StartAbout;