import React from "react";
import computers from "../images/icons/computers.svg";
import worker from "../images/icons/worker.svg";
import deal from '../images/icons/deal.svg';
import aboutCompany from '../images/about/about-company.jpg';


function WhyChoose() {

    return (
        <div>
            <section class="why-choos-lg pad-tb deep-dark">
         <div class="container">
            <div class="row">
               <div class="col-lg-6">
                  <div class="common-heading text-l">
                     <span>Why Choose Us</span>
                     <h2 class="mb20">We are not the <span class="text-second text-bold">only multi-service </span> provider, but why do most clients rely on us?</h2>
                     <p>Success requires nurturing. One of our core principles is to yield an uncompromising level of quality without the expensive service price tag. There are no surprise bills. We see ourselves as an essential member of each of our client's teams, and we understand that our theories tend to focus on their success.</p>
                     <div class="itm-media-object mt40 tilt-3d">
                        {/* <div class="media">
                           <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000"><img src={computers} alt="icon" class="layer"/></div>
                           <div class="media-body">
                              <h4>Plan for success</h4>
                              <p>We have discovered that the efficient method to get your expected outcome is to conduct preliminary research about your business, rivals, target market, and customer preferences. We blueprint strategy only after we truly know you and your customers.</p>
                           </div>
                        </div> */}
                        <div class="media mt40">
                           <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000"><img src={worker} alt="icon" class="layer"/></div>
                           <div class="media-body">
                              <h4>A Dedicated Team of Experts</h4>
                              <p>We have the industry's most trusted names and top minds because our team members have been there and done the most challenging tasks.</p>
                           </div>
                        </div>
                        <div class="media mt40">
                           <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000"> <img src={deal} alt="icon" class="layer"/></div>
                           <div class="media-body">
                              <h4>Completion of Project in Given Time</h4>
                              <p>Many workplaces simply throw off low-quality products to deliver their products on time to their customers, which is unacceptable. In contrast, we practice on-time service delivery as part of our culture, with a happy and well-trained workforce, so that the final result reaches the clients on time without sacrificing quality, instead leaving a bright smile on clients</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-6">
                  <div  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="single-image bg-shape-dez wow fadeIn" data-wow-duration="2s"><img src={aboutCompany} alt="image" class="img-fluid"/></div>
                  <p class="text-center mt30">We have discovered that the efficient method to get your expected outcome is to conduct preliminary research about your business, rivals, target market, and customer preferences. We blueprint strategy only after we truly know you and your customers</p>
                  <div class="cta-card mt60 text-center">
                     <h3 class="mb20">Let's Start a  <span class="text-second text-bold">New Project</span> Together</h3>
                     <p>We believe that precise scope and transparency are the crucial facets of excellence.</p>
                     <a href="/ContactUs" class="niwax-btn1 mt30"><span>Book a Call <i class="fas fa-chevron-right fa-icon"></i></span></a>
                  </div>
               </div>
            </div>
         </div>
      </section>
            
        </div>
        );
}

export default WhyChoose;