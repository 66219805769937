import React from "react";

import shape3 from "../images/shape/shape-3.svg";
import shape4 from "../images/shape/shape-4.svg";
import shape11 from "../images/shape/shape-11.svg";
import shape13 from "../images/shape/shape-13.svg";


function CTA() {

   return (
      <div>

         <section class="cta-area pad-tb">
            <div class="container">
               <div class="row justify-content-center">
                  <div class="col-lg-8">
                     <div class="common-heading">
                        <span>Let's work together</span>
                        <h2>We Love to Listen to Your Requirements</h2>
                        <a href="/ContactUs" class="btn-outline">Contact Us <i class="fas fa-chevron-right fa-icon"></i></a>
                        <p class="cta-call">Or call us now <a href="tel:+91{websiteSetting['mobile']}"><i class="fas fa-phone-alt"></i> +91 89 57 848236</a></p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="shape shape-a1"><img src={shape3} alt="shape" /></div>
            <div class="shape shape-a2"><img src={shape4} alt="shape" /></div>
            <div class="shape shape-a3"><img src={shape13} alt="shape" /></div>
            <div class="shape shape-a4"><img src={shape11} alt="shape" /></div>
         </section>
      </div>
   );
}

export default CTA;