import React from "react";
import { useFormik } from "formik";
import swal from "sweetalert";
import { requestQuoteSchema } from "../schemas/requestQuote";
import axios from "axios";
import img1 from "../images/icons/call.svg"
import img2 from "..//images/icons/whatsapp.svg"
import img3 from "../images/icons/mail.svg"
import img4 from "../images/icons/map.svg"

import { useEffect, useState } from "react";


function Sidebar() {
  
  const [websiteSetting, setWebsiteSetting] = useState('');
  
    useEffect(() => {
      let url;
      url = `${process.env.REACT_APP_BASE_URL}websiteSetting`;
      axios.get(url,
        {
          headers:{
            'Authorization': `Basic ${process.env.REACT_APP_BASIC_AUTH}`
          }
        }).then(function (response) {
          setWebsiteSetting(response.data.data.docs[0]);
        }).catch(function (error) {
        })
    },[])

    const initialValues = {
    name: "",
    email: "",
    message: "",
    mobile: "",
  }
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: requestQuoteSchema,
    onSubmit: (values, action) => {
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASE_URL}contact`,
        headers: {
          'Authorization': `Basic ${process.env.REACT_APP_BASIC_AUTH}`
        },
        method: "POST",
        data: values,
      })
        // Handle the response from backend here
        .then((res) => {
          swal({
            title: "Thank you!",
            text: "We will contact you soon.",
            icon: "success",
          });
          action.resetForm();
        })
        // Catch errors if any
        .catch((err) => {
          swal({
            title: "Oops!!",
            text: "Some issue occured. Try again sometime!!",
            icon: "warning",
          });
        });
      // action.resetForm();
    }
  })
  return (
    <>
      <div class="niwaxofcanvas offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample">
        <div class="offcanvas-body">
          <div class="cbtn animation">
            <div class="btnclose"> <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button></div>
          </div>
          <div class="form-block sidebarform">
            <h4>Request A Quote</h4>
            <form onSubmit={handleSubmit} class="shake mt20">
              <div class="row">
                <div class="form-group col-sm-12">
                  <input className="mb-0 mt-3" type="text" id="name" name="name" placeholder="Enter name" value={values.name} onChange={handleChange} onBlur={handleBlur} required />
                  {errors.name && touched.name ? (<p className="form-error text-danger small mt-0">{errors.name}</p>) : null}
                </div>
                <div class="form-group col-sm-12">
                  <input className="mb-0 mt-3" type="email" id="email" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} placeholder="Enter email" required />
                  {errors.email && touched.email ? (<p className="form-error text-danger small mt-0">{errors.email}</p>) : null}
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-12">
                  <input className="mb-0 mt-3" type="text" id="mobile" name="mobile" value={values.mobile} onChange={handleChange} onBlur={handleBlur} placeholder="Enter mobile" required />
                  {errors.mobile && touched.mobile ? (<p className="form-error text-danger small mt-0">{errors.mobile}</p>) : null}
                </div>
                <div class="form-group col-sm-12">
                  <select name="requirement" className="mb-0 mt-3" id="requirement" value={values.requirement} onChange={handleChange} onBlur={handleBlur} required>
                    <option value="">Designing</option>
                    <option value="web">Development</option>
                    <option value="graphic">Marketing</option>
                    <option value="video">Other</option>
                  </select>
                  {errors.requirement && touched.requirement ? (<p className="form-error text-danger small mt-0">{errors.requirement}</p>) : null}
                </div>
              </div>
              <div class="form-group">
                <textarea id="message" className="mb-0 mt-3" name="message" value={values.message} onChange={handleChange} onBlur={handleBlur} rows="5" placeholder="Enter your message" required></textarea>
                {errors.message && touched.message ? (<p className="form-error text-danger small mt-0">{errors.message}</p>) : null}
              </div>
              <button type="submit" id="form-submit" class="btn lnk btn-main bg-btn mt-4">Submit <span class="circle"></span></button>
              <div id="msgSubmit" class="h3 text-center hidden"></div>
              <div class="clearfix"></div>
            </form>
          </div>
          <div class="getintouchblock mt30">
            <h4>Get In Touch</h4>
            <p class="mt10">Please fill out the form below if you have a plan or project in mind that you'd like to share with us.</p>
            <div class="media mt15">
              <div class="icondive"><img src={img1} alt="icon" /></div>
              <div class="media-body getintouchinfo">
                <a href="tel:{websiteSetting['mobile']}">+91 {websiteSetting['mobile']} <span>Mon-Fri 9am - 6pm</span></a>
              </div>
            </div>
            <div class="media mt15">
              <div class="icondive"><img src={img2} alt="icon" /></div>
              <div class="media-body getintouchinfo">
                <a href="tel:{websiteSetting['mobile']}">+91 8957 848 236 <span>Mon-Fri 9am - 6pm</span></a>
              </div>
            </div>
            <div class="media mt15">
              <div class="icondive"><img src={img3} alt="icon" /></div>
              <div class="media-body getintouchinfo">
                <a href="mailto:support@makeitsimple.net.in"><span class="__cf_email__" data-cfemail="0a63646c654a7d6f6879637e6f24696567">support@makeitsimple.net.in</span> <span>Online Support</span></a>
              </div>
            </div>
            <div class="media mt15">
              <div class="icondive"><img src={img4} alt="icon" /></div>
              <div class="media-body getintouchinfo">
                <a href="javascript:void(0)">OPS Nagar, Kalindipuram, Prayagraj, Uttar Pradesh, 211011<span>Visit Our Office</span></a>
              </div>
            </div>
          </div>
          <div class="contact-data mt30">
            <h4>Follow Us On:</h4>
            <div class="social-media-linkz mt10">
              <a href="javascript:void(0)" target="blank"><i class="fab fa-facebook"></i></a>
              <a href="javascript:void(0)" target="blank"><i class="fab fa-twitter"></i></a>
              <a href="javascript:void(0)" target="blank"><i class="fab fa-instagram"></i></a>
              <a href="javascript:void(0)" target="blank"><i class="fab fa-linkedin"></i></a>
              <a href="javascript:void(0)" target="blank"><i class="fab fa-youtube"></i></a>
              <a href="javascript:void(0)" target="blank"><i class="fab fa-pinterest-p"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="niwaxofcanvas offcanvas offcanvas-end" tabindex="-1" id="hamBurgerCanvasExample">
        <div class="offcanvas-body bounceindown">
          <div class="cbtn animation">
            <div class="btnclose"> <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button></div>
          </div>
          <h3 className="border-bottom pb-3 text-center mt-3 text-effect-2 text-animation">MIS MENU</h3>
          <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <a href="/">
              <h5 className="my-2">
                Home
              </h5>
            </a>
            <a href="/about">
              <h5 className="my-2">
                About
              </h5></a>
            <a href="/mission">
              <h5 className="my-2">
                Our Mission
              </h5></a>
            <a href="/price">
              <h5 className="my-2">
                Price
              </h5></a>
            <a href="/testimonial">
              <h5 className="my-2">
                Testimonial
              </h5></a>
            <a href="/portfolio">
              <h5 className="my-2">
                Portfolio
              </h5></a>
            <a href="/contactUs">
              <h5 className="my-2">
                Contact Us
              </h5></a></div>
        </div>
      </div>
    </>

  );
}

export default Sidebar;
