import Header from "./Header2";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import { useState } from "react";
import img from "../images/noImage.jpeg"
import { useEffect } from "react";
const axios = require('axios').default;

function Testimonial() {

  const [options, setOptions] = useState({
    lazyLoad: true,
    loop: true,
    margin: 10,
    autoplay: true,
    dots: false,
    slideSpeed: 3000,
    autoplayTimeout: 7500,
    margin: 20,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  });
  const [testimonials, setTestimonials] = useState('');
  const [testimonial, setTestimonial] = useState([]);

  useEffect(() => {
    let url;
    url = `${process.env.REACT_APP_BASE_URL}testimonial?page=1&limit=12&sortBy=asc&status=1`;
    axios.get(url,
      {
        headers:{
          'Authorization': `Basic ${process.env.REACT_APP_BASIC_AUTH}`
        }
      }).then(function (response) {
        setTestimonial(response.data.data.docs);
        setTestimonials(response.data.data.docs);
        console.log('response data',response.data);
        console.log('testimonials length',testimonials.length);
        console.log('testimonial length',testimonial.length);
        console.log('testimonials ',testimonials);
        console.log('testimonial ;',testimonial);
      }).catch(function (error) {
      console.log("🚀 ~ file: Testimonial.jsx:24 ~ useEffect ~ error", error)
      })
  },[])

  return (
    <>
      <Header />
      <section class="breadcrumb-area banner-2">
        <div class="text-block">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 v-center">
                <div class="bread-inner">
                  <div class="bread-menu">
                    <ul>
                      <li>
                        <a href="index-2.html">Home</a>
                      </li>
                      <li>
                        <a href="#">Client Reviews</a>
                      </li>
                    </ul>
                  </div>
                  <div class="bread-title">
                    <h2>Client Reviews</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <section className="bg-gradient1 pad-tb dark-bg1">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt30">

                <OwlCarousel className="owl-theme"  {...options}>
                  {
                    testimonials?.length > 0 ? testimonials.map((item) => {
                      const { description, image, title } = item;
                      return (
                        <>
                          <div className="reviews-card pr-shadow" style={{ minHeight: 360 }}>
                            <div className="row v-center">
                              <div className="col">
                                {" "}
                                <span className="revbx-lr"></span>{" "}
                              </div>
                              <div className="-client-details- d-flex justify-content-center ">
                                <div>
                                  <img
                                    src={process.env.REACT_APP_BASE_IMAGE_URL + '/media/preview?filename=' + image}
                                    alt={title}
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-center">
                                <h5>{title}</h5>
                              </div>
                            </div>
                            <div className="review-text">
                              {description}
                            </div>
                          </div>
                        </>
                      )
                    }):
                    <>
                    <div className="d-flex justify-content-center flex-column" style={{ width: '450px' }} >
                      <img src={img} alt="" />
                      <h5 className="text-center">No Data Found</h5>
                    </div>
                  </>
                  }

                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Testimonial;
