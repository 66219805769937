import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Index from "./Components/index";
import Footer from "./Components/Pages/Footer";
import Sidebar from "./Components/Pages/Sidebar";
import About from "./Components/Pages/About";
import Demo from "./Components/Pages/Demo";
import HeaderPortfolio from "./Components/Pages/HeaderPortfolio";
import Price from "./Components/Pages/Price";
import ContactUs from "./Components/Pages/ContactUs";
import Testimonial from "./Components/Pages/Testimonial";
import Mission from "./Components/Pages/Mission";

function App() {
  return (
    
    <Router>
      <Sidebar />
      <div>
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/about" element={<About/>} />
            <Route path="/portfolio" element={<HeaderPortfolio/>} />
            <Route path="/price" element={<Price/>} />
            <Route path="/demo" element={<Demo/>} />
            <Route path="/testimonial" element={<Testimonial/>} />\
            <Route path="/contactUs" element={<ContactUs/>}/>
            <Route path="/mission" element={<Mission/>}/>
          </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
