import React from "react";

import rome from "../images/location/rome.png";
import newyork from "../images/location/newyork.png";
import sydney from "../images/location/sydeny.png";


function Location() {

    return (
        <div>
           <section class="our-office pad-tb">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-lg-8">
                  <div class="common-heading">
                     <span>Our Locations</span>
                     <h2>Our Office</h2>
                  </div>
               </div>
            </div>
            <div class="row justify-content-center upset shape-numm">
               <div class="col-lg-6 col-sm-6 shape-loc wow fadeInUp" data-wow-delay=".2s">
                  <div class="office-card">
                     <div class="skyline-img" data-tilt data-tilt-max="4" data-tilt-speed="1000">
                        <img src={newyork} alt="New York" class="img-fluid" />
                     </div>
                     <div class="office-text">
                        <h4>Prayagraj</h4>
                        <p>Kalindipuram, Prayagraj, Uttar Pradesh</p>
                        <div className="d-flex align-items-center"><a href="javascript:void(0)" target="blank" class="btn-outline rount-btn m-1 d-flex justify-content-center align-items-center"><i class="fas fa-map-marker-alt"></i></a>
                        <a href="javascript:void(0)" target="blank" class="btn-outline rount-btn m-1 d-flex justify-content-center align-items-center"><i class="fas fa-phone-alt"></i></a>
                        <a href="javascript:void(0)" target="blank" class="btn-outline rount-btn m-1 d-flex justify-content-center align-items-center"><i class="fas fa-envelope"></i></a>
                        <a href="javascript:void(0)" target="blank" class="btn-outline rount-btn m-1 d-flex justify-content-center align-items-center"><i class="fab fa-skype"></i></a></div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-6 col-sm-6 shape-loc wow fadeInUp" data-wow-delay=".5s">
                  <div class="office-card">
                     <div class="skyline-img" data-tilt data-tilt-max="4" data-tilt-speed="1000">
                        <img src={sydney} alt="sydney" class="img-fluid" />
                     </div>
                     <div class="office-text">
                        <h4>Bangalore</h4>
                        <p>Indiranagar, Bangalore, Karnataka </p>
                        <div className="d-flex align-items-center"><a href="javascript:void(0)" target="blank" class="btn-outline rount-btn m-1 d-flex justify-content-center align-items-center"><i class="fas fa-map-marker-alt"></i></a>
                        <a href="javascript:void(0)" target="blank" class="btn-outline rount-btn m-1 d-flex justify-content-center align-items-center"><i class="fas fa-phone-alt"></i></a>
                        <a href="javascript:void(0)" target="blank" class="btn-outline rount-btn m-1 d-flex justify-content-center align-items-center"><i class="fas fa-envelope"></i></a>
                        <a href="javascript:void(0)" target="blank" class="btn-outline rount-btn m-1 d-flex justify-content-center align-items-center"><i class="fab fa-skype"></i></a></div>
                     </div>
                  </div>
               </div>
               
            </div>
         </div>
      </section> 
            
        </div>
        );
}

export default Location;