import React from "react";
import img1 from "../images/servicelogo/Graphic Designing.png"
import img2 from "../images/servicelogo/motion-graphic.png"
import img3 from "../images/servicelogo/3d.png"
import img4 from "../images/servicelogo/Digital Painting.png"
import img5 from "../images/servicelogo/Web Development.png"
import img6 from "../images/servicelogo/Video Editing.png"
import img7 from "../images/servicelogo/App Development.png"
import img8 from "../images/servicelogo/Digital Marketing.png"

function StartService() {

    return (
        <div>
            
            <section class="service-section-prb pad-tb">
         <div class="container">
            <div class="row upset">
               <div  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-6-cus wow fadeInUp" data-wow-delay=".2s">
                  <div class="service-sec-brp srvc-bg-nx bg-gradient13 text-w">
                     <h4 class="mb10">INTEGRATED SERVICES</h4>
                     <p>Where we create a synergy of powerful functionality and beautiful aesthetics to give your idea a tangible form.</p>
                     <a href="/portfolio" class="mt20 link-prb">Learn More <i class="fas fa-chevron-right fa-icon"></i></a>
                  </div>
               </div>
               <div  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus wow fadeInUp" data-wow-delay=".4s">
                  <div class="service-sec-list srvc-bg-nx srcl1">
                     <img src={img1} alt="service"/>
                     <h5 class="mb10">Graphic Designing</h5>
                     {/* <ul class="-service-list">
                        <li> <a href="#">PHP</a> </li>
                        <li> <a href="#"><strong>.</strong>Net</a> </li>
                        <li> <a href="#">Java</a> </li>
                        <li> <a href="#">Joomla</a></li>
                     </ul> */}
                     <p>When most people think of graphic design, they think of designs created for marketing and advertising. Here at Make It Simple it is our duty to provide clients the designs they dream of!</p>
                  </div>
               </div>
               <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus wow fadeInUp" data-wow-delay=".6s">
                  <div class="service-sec-list srvc-bg-nx srcl2">
                     <img src={img2} alt="service"/>
                     <h5 class="mb10">Motion Graphics</h5>
                     {/* <ul class="-service-list">
                        <li> <a href="#">Magento </a> </li>
                        <li> <a href="#">WP</a> </li>
                        <li> <a href="#">Shopify </a> </li>
                        <li> <a href="#">Joomla</a></li>
                     </ul> */}
                     <p>Though ‘concept’ and ‘visualization’ connect the dots back to the notion of telling an invigorating ‘story’, our artists make sure that clients can express their story through Motion Graphics.</p>
                  </div>
               </div>
               <div  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus mt30- wow fadeInUp" data-wow-delay=".8s">
                  <div class="service-sec-list srvc-bg-nx srcl3">
                     <img src={img3} alt="service"/>
                     <h5 class="mb10">3D Services</h5>
                     {/* <ul class="-service-list">
                        <li> <a href="#">iPhone </a> </li>
                        <li> <a href="#">Android</a> </li>
                        <li> <a href="#">Cross Platform </a></li>
                     </ul> */}
                     <p>Create your own three-dimensional representation here!</p>
                  </div>
               </div>
               <div  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus mt30- wow fadeInUp" data-wow-delay="1s">
                  <div class="service-sec-list srvc-bg-nx srcl4">
                     <img src={img4} alt="service"/>
                     <h5 class="mb10">Digital Painting</h5>
                     {/* <ul class="-service-list">
                        <li> <a href="#">React.JS </a> </li>
                        <li> <a href="#">Node.JS </a> </li>
                        <li> <a href="#"> Angular.JS </a></li>
                     </ul> */}
                     <p>The artist uses painting and drawing techniques with a stylus. Let our artists paint the face of your company!</p>
                  </div>
               </div>
               <div  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-6-cus mt30- wow fadeInUp" data-wow-delay="1.2s">
                  <div class="service-sec-list srvc-bg-nx srcl5">
                     <img src={img5} alt="service"/>
                     <h5 class="mb10">Web Development</h5>
                     {/* <ul class="-service-list">
                        <li> <a href="#">SEO </a> </li>
                        <li> <a href="#">SMO </a> </li>
                        <li> <a href="#">PPC </a></li>
                        <li> <a href="#">PPC </a></li>
                     </ul> */}
                     <p>Want to develop a website and show it on the internet? Our developers ensure that your website is visually appealing and easy to navigate.</p>
                  </div>
               </div>
               <div  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-6-cus mt30- wow fadeInUp" data-wow-delay=".4s">
                  <div class="service-sec-list srvc-bg-nx srcl6">
                     <img src={img6} alt="service"/>
                     <h5 class="mb10">Video Editing</h5>
                     {/* <ul class="-service-list">
                        <li> <a href="#">PHP</a> </li>
                        <li> <a href="#"><strong>.</strong>Net</a> </li>
                        <li> <a href="#">Java</a> </li>
                        <li> <a href="#">Joomla</a></li>
                     </ul> */}
                     <p>Editing is the most exciting and satisfying part of making a video or film. This is the climax of the story. If you want the most awesomest climax of your story, you have come to the right place.</p>
                  </div>
               </div>
               <div data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus mt30- wow fadeInUp" data-wow-delay=".6s">
                  <div class="service-sec-list srvc-bg-nx srcl2">
                     <img src={img7} alt="service"/>
                     <h5 class="mb10">App Development</h5>
                     {/* <ul class="-service-list">
                        <li> <a href="#">Magento </a> </li>
                        <li> <a href="#">WP</a> </li>
                        <li> <a href="#">Shopify </a> </li>
                        <li> <a href="#">Joomla</a></li>
                     </ul> */}
                     <p>Get your mobile applications up and ready in no time without any hassle. </p>
                  </div>
               </div>
               <div  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus mt30- wow fadeInUp" data-wow-delay=".8s">
                  <div class="service-sec-list srvc-bg-nx srcl3">
                     <img src={img8} alt="service"/>
                     <h5 class="mb10">Digital Marketing</h5>
                     {/* <ul class="-service-list">
                        <li> <a href="#">iPhone </a> </li>
                        <li> <a href="#">Android</a> </li>
                        <li> <a href="#">Cross Platform </a></li>
                     </ul> */}
                     <p>Promote your brand and connect potential customers using our digital marketers.</p>
                  </div>
               </div>
            </div>
            <div class="-cta-btn mt70">
               <div class="free-cta-title v-center zoomInDown wow" data-wow-delay="1.4s">
                  <p> <span>Let's collaborate.</span></p>
                  <a href="/ContactUs" class="btn-main bg-btn2 lnk">Book a Call<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
               </div>
            </div>
         </div>
      </section>
        </div>
        );
}

export default StartService;