import { useEffect, useState } from 'react';
import axios from 'axios';
import Header2 from "./Header2";
import { ImArrowRight } from "react-icons/im";


function About() {
  
  return (
    <>
      <Header2 />
      {/* <section
        class="breadcrumb-area banner-1"
        style={{
          backgroundImage: "url(" + "../images/banner/9.jpg" + ")",
        }}
      >
        <div class="text-block">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 v-center">
                
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section class="breadcrumb-area banner-1" style={{
        backgroundImage: "url(" + "../images/banner/9.jpg" + ")",
      }} data-background="images/banner/9.jpg">
        <div class="text-block">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 v-center">
                <div class="bread-inner">
                  <div class="bread-menu">
                    <ul>
                      <li><a href="index-2.html">Home</a></li>
                      <li><a href="#">About Us</a></li>
                    </ul>
                  </div>
                  <div class="bread-title">
                    <h2>About Company</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="about-agency pad-tb block-1">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 v-center">
              <div class="about-image">
                <img src="images/about/company-about.png" alt="about us" class="img-fluid" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="common-heading text-l ">
                <span>About Us</span>
                <h2>About Agency</h2>
                <p>We are a creative agency which represents considerable authority for all your designing and development needs to suit all of your basic requirements.
                  With our efficient and creative team we value offering the best quality to both expansive organizations and little scale organizations alike. We are lucky to work with you who improves us and push us to a greater trust, who need to do things that have never been finished.
                </p>
                <p>We offer a various, far-reaching scope of showcasing arrangements spreading over brand publicizing, client relationship, advertising, and various services to give primary results for our customers.
                  We are the ONE STOP solution for all your designing needs.
                </p>
              </div>
              <div class="row in-stats small about-statistics">
                <div class="col-lg-4 col-sm-4">
                  <div class="statistics">
                    <div class="statnumb counter-number">
                      <span class="counter">100+</span>
                      <p>Happy Clients</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-4">
                  <div class="statistics">
                    <div class="statnumb">
                      <span class="counter">10</span><span>k+</span>
                      <p>Hours Worked</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-4">
                  <div class="statistics mb0">
                    <div class="statnumb counter-number">
                      <span class="counter">250+</span>
                      <p>Projects Done</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="why-choose pad-tb">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <div class="common-heading">
                <span>We Are Awesome</span>
                <h2 class="mb30">Why Choose Us</h2>
              </div>
            </div>
          </div>
          <div class="row upset">
            <div class="col-lg-3 col-sm-6 mt30">
              <div class="s-block up-hor" style={{minHeight: 510}}>
                <div class="s-card-icon"><img src="images/icons/research.svg" alt="service" class="img-fluid" /></div>
                <h4>Reasearch and Analysis</h4>
                <p>We have worked with almost every type of major industry. Make It Simple has helped hundreds of businesses with their online marketing efforts.</p>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mt30">
              <div class="s-block up-hor">
                <div class="s-card-icon"><img src="images/icons/chat.svg" alt="service" class="img-fluid" /></div>
                <h4>Negotiation and power</h4>
                <p>Our prices are competitive and fair. There are no surprise bills. Any unexpected or additional expenses must be pre-approved by you. That’s how we would like to be treated, and that is how our clients are treated.</p>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mt30">
              <div class="s-block up-hor" style={{minHeight: 510}}>
                <div class="s-card-icon"><img src="images/icons/monitor.svg" alt="service" class="img-fluid" /></div>
                <h4>Creative and innovative solutions</h4>
                <p>We bring our diverse background of designing, advertising, branding to work with your company. </p>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mt30">
              <div class="s-block up-hor" style={{minHeight: 510}}>
                <div class="s-card-icon"><img src="images/icons/trasparency.svg" alt="service" class="img-fluid" /></div>
                <h4>Trasparency and ease of work</h4>
                <p>Make It Simple guides you through the artistic journey. With our easy and affordable subscription based services you can get your expected results in no time!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pricing-block pad-tb">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div class="common-heading">
                <span>Our Core Values</span>
                <h2 class="mb30">Why Choose Us</h2>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 nxpb-1">
              <div class="niwaxpricingbox shadow" >
                <div
                  class="pricingheader"
                  style={{minHeight:235}}
                >
                  <h2>Our Mission</h2>
                  <p>To exceed customers expectations with innovation and bespoke Assurance, Testing, Inspection and Certification services for their operations and supply chain. Globally 24/7.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 nxpb-2">
              <div class="niwaxpricingbox shadow reco">
                <div
                  class="pricingheader"
                >
                  <h2>Our Vision</h2>
                  <p>We are a global family that values diversity. We always do the right thing. With precision, pace and passion. We trust each other and have winning together. We own and shape our future. We create sustainable growth. For All.</p>
                </div>

              </div>
            </div>
            <div class="col-lg-4 col-md-6 nxpb-3">
              <div class="niwaxpricingbox shadow">
                <div
                  class="pricingheader"
                >
                  <h2>Our Values</h2>
                  <p>Responsive: We adapt, to the industry and to your business. Relentless: We aim for greatness in all we do. Resilient: We evolve with the market. To be the world’s most trusted partner for Quality Assurance.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
