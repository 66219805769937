import React, { useState } from "react";
import Statistics from "./Pages/Statistics";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from "./Pages/Header";
// import swal from 'sweetalert';
import img2 from "./images/service/icon_Motion Graphics.png";
import img3 from "./images/service/icon_graphic designing.png";
import img4 from "./images/service/icon_Digital Marcketing.png";
import img5 from "./images/service/icon_Web Deveoloper.png";
import img6 from "./images/service/icon_App Deveoloper.png"
import img7 from "./images/service/icon_Digital Art.png"
import img8 from "./images/service/icon_3D.png";
import img9 from "./images/service/icon_Video Editing.png"
import img10 from "./images/service/icon_interior Exterioe.png"
import img11 from "./images/service/icon_Software Developent.png"
import pdf from "./images/mis.pdf";
import WhyChoose from "./Pages/WhyChoose";
import StartAbout from "./Pages/StartAbout";
import StartService from "./Pages/StartService";
// import awardsLogo from "./images/hero/awards-logo.png";
import Clients from "./Pages/Clients";
import Badges from "./Pages/Badges";
import WorkCategory from "./Pages/WorkCategory";
import CTA from "./Pages/CTA";
import Location from "./Pages/Location";

function Index() {
   const [options, setOptions] = useState({
      lazyLoad: true,
      loop: true,
      margin: 10,
      autoplay: false,
      dots: false,
      slideSpeed: 3000,
      autoplayTimeout: 7500,
      margin: 20,
      nav: false,
      responsive: {
         0: {
            items: 1,
         },
         600: {
            items: 2,
         },
         1000: {
            items: 3,
         },
      },
   }
   );
   return (
      <>
         <Header />
         <div>
            <section className="hero-card-web bg-gradient12 shape-bg3">
               <div className="hero-main-rp container-fluid">
                  <div className="row">
                     <div className="col-lg-5" style={{ marginTop: '10%' }}>
                        <div className="hero-heading-sec">
                           <h2 className="wow fadeIn" data-wow-delay="0.3s"><span>Web</span> <span>Mobile</span> <span>Graphic</span> <span>Marketing</span></h2>
                           <p className="wow fadeIn" data-wow-delay="0.6s">Branding Solution for transforming and innovating businesses</p>
                           <div className="row">
                              <div className="col-md-6 col-sm-12 mt-2">
                                 <a href={pdf} target="_blank" rel="noreferrer" download className="niwax-btn2 wow fadeIn" data-wow-delay="0.8s">Download Brochure <i className="fas fa-download fa-ani"></i></a>
                              </div>
                              <div className="col-md-6 col-sm-12 mt-2">
                                 <a href="/ContactUs" className="niwax-btn2 wow fadeIn" data-wow-delay="0.8s">Schedule Demo <i className="fas fa-calendar fa-ani"></i></a>
                              </div>
                           </div>
                           {/* <div className="awards-block-tt  wow fadeIn" data-wow-delay="1s"><img src={awardsLogo} alt="awards-logo" className="img-fluid" /></div> */}
                        </div>
                     </div>
                     <div className="col-lg-7" style={{ marginTop: '10%', marginBottom: '15%' }}>
                        <div className="hero-content-sec wow fadeIn" data-wow-delay="0.8s">
                           <div className="video-intro-pp"><a target="_blank" className="video-link play-video" href="https://www.youtube.com/watch?v=wBnDvA2HHV0&t=19s"><span className="triangle-play"></span></a></div>
                           <div className="title-hero-oth">
                              <p>We design digital solutions <span>for brands and companies</span></p>
                           </div>
                        </div>
                        <div className="hero-right-scmm">
                           <OwlCarousel className='owl-theme' {...options}>
                              <div className="service-slide card-bg-a">
                                 <a href="#">
                                    <div className="service-card-hh">
                                       <div className="image-sr-mm">
                                          <img alt="custom-sport" src={img2} />
                                       </div>
                                       <div className="title-serv-c"><span>Motion</span> Graphics</div>
                                    </div>
                                 </a>
                              </div>
                              <div className="service-slide card-bg-b">
                                 <a href="#">
                                    <div className="service-card-hh">
                                       <div className="image-sr-mm">
                                          <img alt="custom-sport" src={img3} />
                                       </div>
                                       <div className="title-serv-c"><span>Graphic</span> Designing</div>
                                    </div>
                                 </a>
                              </div>
                              <div className="service-slide card-bg-c">
                                 <a href="#">
                                    <div className="service-card-hh">
                                       <div className="image-sr-mm">
                                          <img alt="custom-sport" src={img4} />
                                       </div>
                                       <div className="title-serv-c"><span>Digital</span> Marketing</div>
                                    </div>
                                 </a>
                              </div>
                              <div className="service-slide card-bg-d">
                                 <a href="#">
                                    <div className="service-card-hh">
                                       <div className="image-sr-mm">
                                          <img alt="custom-sport" src={img5} />
                                       </div>
                                       <div className="title-serv-c"><span>Web</span> Development</div>
                                    </div>
                                 </a>
                              </div>
                              <div className="service-slide card-bg-e" >
                                 <a href="#">
                                    <div className="service-card-hh">
                                       <div className="image-sr-mm">
                                          <img alt="custom-sport" src={img6} />
                                       </div>
                                       <div className="title-serv-c"><span>App</span> Development</div>
                                    </div>
                                 </a>
                              </div>
                              <div className="service-slide card-bg-f" >
                                 <a href="#">
                                    <div className="service-card-hh">
                                       <div className="image-sr-mm">
                                          <img alt="custom-sport" src={img7} />
                                       </div>
                                       <div className="title-serv-c"><span>Digital</span> Painting</div>
                                    </div>
                                 </a>
                              </div>
                              <div className="service-slide card-bg-g">
                                 <a href="#">
                                    <div className="service-card-hh">
                                       <div className="image-sr-mm">
                                          <img alt="custom-sport" src={img8} />
                                       </div>
                                       <div className="title-serv-c"><span>3D</span> Services</div>
                                    </div>
                                 </a>
                              </div>
                              <div className="service-slide card-bg-h">
                                 <a href="#">
                                    <div className="service-card-hh">
                                       <div className="image-sr-mm">
                                          <img alt="custom-sport" src={img9} />
                                       </div>
                                       <div className="title-serv-c"><span>Video</span> Editing</div>
                                    </div>
                                 </a>
                              </div>
                              <div className="service-slide card-bg-i">
                                 <a href="#">
                                    <div className="service-card-hh">
                                       <div className="image-sr-mm">
                                          <img alt="custom-sport" src={img10} />
                                       </div>
                                       <div className="title-serv-c"><span>Interior Exterior</span> Designing</div>
                                    </div>
                                 </a>
                              </div>
                               <div className="service-slide card-bg-j">
                                 <a href="#">
                                    <div className="service-card-hh">
                                       <div className="image-sr-mm">
                                          <img alt="custom-sport" src={img11} />
                                       </div>
                                       <div className="title-serv-c"><span>Software</span> Development</div>
                                    </div>
                                 </a>
                              </div>
                           </OwlCarousel>
                           {/* </div> */}
                           {/* </div> */}
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <StartAbout />
            <StartService />
            <Statistics />
            <WhyChoose />
            <Clients />
            <Badges />
            <WorkCategory />
            <div>
               {/* <section className="testinomial-section bg-none pad-tb">
                  <div className="container">
                     <div className="row justify-content-center ">
                        <div className="col-lg-8">
                           <div className="common-heading">
                              <span>Make It Simple</span>
                              <h2>Over 1200+ Satisfied Clients and Growing</h2>
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-lg-6">
                           <div className="video-testimonials owl-carousel">
                              <div className="video-review" data-tilt data-tilt-max="5" data-tilt-speed="1000">
                                 <a className="video-link" href="https://www.youtube.com/watch?v=SZEflIVnhH8?autoplay=1&amp;rel=0">
                                    <img src={pic} alt="client" className="img-fluid" />
                                    <div className="review-vid-details">
                                       <div className="-vid-ico"><span className="triangle-play2"></span></div>
                                       <p>Cina Cleaves</p>
                                    </div>
                                 </a>
                              </div>
                              <div className="video-review" data-tilt data-tilt-max="5" data-tilt-speed="1000">
                                 <a className="video-link" href="https://www.youtube.com/watch?v=SZEflIVnhH8?autoplay=1&amp;rel=0">
                                    <img src={picA} alt="client" className="img-fluid" />
                                    <div className="review-vid-details">
                                       <div className="-vid-ico"><span className="triangle-play2"></span></div>
                                       <p>Jokvch Marlin</p>
                                    </div>
                                 </a>
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-6">
                           <div className="owl-carousel testimonial-card-a pl25">
                              <div className="testimonial-card">
                                 <div className="t-text">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                 </div>
                                 <div className="client-thumbs mt30">
                                    <div className="media v-center">
                                       <div className="user-image bdr-radius"><img src={girl} alt="girl" className="img-fluid" /></div>
                                       <div className="media-body user-info v-center">
                                          <h5>Mike Smith</h5>
                                          <p>Business Owner, <small>Sydney, Australia</small></p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="testimonial-card">
                                 <div className="t-text">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                 </div>
                                 <div className="client-thumbs mt30">
                                    <div className="media v-center">
                                       <div className="user-image bdr-radius"><img src={girl} alt="girl" className="img-fluid" /></div>
                                       <div className="media-body user-info">
                                          <h5>Fred Chener</h5>
                                          <p>CEO of Aramxx, <small>Ohio, US State</small></p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="testimonial-card">
                                 <div className="t-text">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                 </div>
                                 <div className="client-thumbs mt30">
                                    <div className="media v-center">
                                       <div className="user-image bdr-radius"><img src={girl} alt="girl" className="img-fluid" /></div>
                                       <div className="media-body user-info">
                                          <h5>Mathilda Burns</h5>
                                          <p>Backery Shop, <small>Tronto, Italy</small></p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="row justify-content-center">
                        <div className="col-lg-12">
                           <div className="review-ref mt100">
                              <div className="review-title-ref">
                                 <h4>Read More Reviews</h4>
                                 <p>Read our reviews from all over world.</p>
                              </div>
                              <div className="review-icons">
                                 <a href="#" target="blank" className="wow fadeIn" data-wow-delay=".2s"><img src={icon1} alt="review" className="img-fluid" /></a>
                                 <a href="#" target="blank" className="wow fadeIn" data-wow-delay=".4s"><img src={icon2} alt="review" className="img-fluid" /></a>
                                 <a href="#" target="blank" className="wow fadeIn" data-wow-delay=".6s"><img src={icon3} alt="review" className="img-fluid" /></a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section> */}
            </div>
            <CTA />
            <Location />

         </div>
      </>
   );
}

export default Index;