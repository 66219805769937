import React from "react";
import logo1 from "../images/client/Ataraxia IP.png"
import logo2 from "../images/client/brandlabs.png"
import logo3 from "../images/client/Dangal Games.png"
import logo4 from "../images/client/Eternia.png"
import logo5 from "../images/client/EWar.png"
import logo6 from "../images/client/Fantasy Dangal.png"
import logo7 from "../images/client/Fruitboxes.png"
import logo8 from "../images/client/GKON.png"
import logo9 from "../images/client/Greenthumbs.png"
import logo10 from "../images/client/IP Samadhan.png"
import logo11 from "../images/client/iQuanta.png"
import logo12 from "../images/client/Mozu.png"
import logo13 from "../images/client/ralph&das.png"
import logo14 from "../images/client/Seafoodbinge.png"
import logo15 from "../images/client/vGuard.png"
import logo16 from "../images/client/Verzeo.png"
import logo17 from "../images/client/Linking Fashion.png"
import logo18 from "../images/client/Mangoeswala.png"



function Clients() {
    return (
        <div>
            <section class="clients-section- bg-gradient15 pad-tb">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-lg-8">
                  <div class="common-heading">
                     <span>Our happy customers</span>
                     <h2 class="mb30">Some of our Clients</h2>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="clients-logos text-center col-12">
                     <ul class="row text-center clearfix">
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay=".2s">
                           <div class="brand-logo"><img src={logo1} alt="clients" class="img-fluid"/></div>
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay=".4s">
                           <div class="brand-logo"><img src={logo2} alt="clients" class="img-fluid"/></div>
      
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay=".6s">
                           <div class="brand-logo"><img src={logo3} alt="clients" class="img-fluid"/></div>

                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay=".8s">
                           <div class="brand-logo"><img src={logo4} alt="clients" class="img-fluid"/></div>
                  
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay="1s">
                           <div class="brand-logo"><img src={logo5} alt="clients" class="img-fluid"/></div>
                  
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay="1.2s">
                           <div class="brand-logo"><img src={logo6} alt="clients" class="img-fluid"/></div>
            
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay="1.4s">
                           <div class="brand-logo"><img src={logo7} alt="clients" class="img-fluid"/></div>
            
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay="1.6s">
                           <div class="brand-logo"><img src={logo8} alt="clients" class="img-fluid"/></div>
                     
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay="1.8s">
                           <div class="brand-logo"><img src={logo9} alt="clients" class="img-fluid"/></div>
         
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay="2s">
                           <div class="brand-logo"><img src={logo10} alt="clients" class="img-fluid"/></div>
            
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay="2.2s">
                           <div class="brand-logo"><img src={logo11} alt="clients" class="img-fluid"/></div>
               
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay="2.4s">
                           <div class="brand-logo"><img src={logo12} alt="clients" class="img-fluid"/></div>
   
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay="2.4s">
                           <div class="brand-logo"><img src={logo13} alt="clients" class="img-fluid"/></div>
   
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay="2.4s">
                           <div class="brand-logo"><img src={logo14} alt="clients" class="img-fluid"/></div>
   
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay="2.4s">
                           <div class="brand-logo"><img src={logo15} alt="clients" class="img-fluid"/></div>
   
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay="2.4s">
                           <div class="brand-logo"><img src={logo16} alt="clients" class="img-fluid"/></div>
   
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay="2.4s">
                           <div class="brand-logo"><img src={logo17} alt="clients" class="img-fluid"/></div>
   
                        </li>
                        <li class="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn" data-wow-delay="2.4s">
                           <div class="brand-logo"><img src={logo18} alt="clients" class="img-fluid"/></div>
   
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </section>
            
        </div>
        );
}

export default Clients;