import React from "react";



function Badges() {

   return (
      <div>
         <section class="badges-section bg-gradient5  pad-tb">
            <div class="container">
               {/* <div class="row justify-content-center">
                  <div class="col-lg-8">
                     <div class="common-heading w-tdxt">
                        <span>WE MAKE RELATIONSHIPS</span>
                        <h2>Ranked as #1 Top Web & App Development Companies</h2>
                     </div>
                  </div>
               </div> */}
               {/* <div class="row">
                  <div class="col-lg-3 col-md-6 col-6 wow fadeIn" data-wow-delay=".2s">
                     <div class="badges-content">
                        <img src={badgesA} alt="budges" class="img-fluid" />
                        <p>ABC DEF Industry Leader</p>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-6 wow fadeIn" data-wow-delay=".4s">
                     <div class="badges-content">
                        <img src={badgesB} alt="budges" class="img-fluid" />
                        <p>Best eCommerce Development Company</p>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-6 wow fadeIn" data-wow-delay=".6s">
                     <div class="badges-content">
                        <img src={badgesC} alt="budges" class="img-fluid" />
                        <p>ABC DEF High Performer Winner</p>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-6 wow fadeIn" data-wow-delay=".8s">
                     <div class="badges-content">
                        <img src={badgesD} alt="budges" class="img-fluid" />
                        <p>Top App Developer 2019-20</p>
                     </div>
                  </div>
               </div> */}
               <div class="-cta-btn mt70">
                  <div class="free-cta-title v-center wow fadeInUp" data-wow-delay="1s">
                     <p>We <span>Promise.</span> We <span>Deliver.</span></p>
                     <a href="/ContactUs" class="btn-main bg-btn2 lnk">Let's Work Together<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></a>
                  </div>
               </div>
            </div>
         </section>

      </div>
   );
}

export default Badges;