import React from "react";
import startup from '../images/icons/startup.svg';
import team from '../images/icons/team.svg';
import deal from '../images/icons/deal.svg';
import worker from '../images/icons/worker.svg';
import customerService from '../images/icons/customer-service.svg';
import computers from "../images/icons/computers.svg";


function Statistics() {

    return (
        <div>
            <div class="statistics-section bg-gradient6 pad-tb tilt3d">
         <div class="container">
            <div class="row justify-content-center t-ctr">
               <div class="col-lg-4 col-sm-6">
                  <div class="statistics">
                     <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                        <img src={startup} alt="years" class="img-fluid" />
                     </div>
                     <div class="statnumb">
                        <span class="counter">1.5</span><span>+</span>
                        <p>Year In Business</p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-4 col-sm-6">
                  <div class="statistics">
                     <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                        <img src={team} alt="team" class="img-fluid" />
                     </div>
                     <div class="statnumb">
                        <span class="counter">15</span><span>+</span>
                        <p>Team Members</p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row small t-ctr">
               <div class="col-lg-3 col-sm-6">
                  <div class="statistics">
                     <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                        <img src={deal} alt="happy" class="img-fluid" />
                     </div>
                     <div class="statnumb">
                        <span class="counter">100</span><span>+</span>
                        <p>Happy Clients</p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-sm-6">
                  <div class="statistics">
                     <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                        <img src={computers} alt="project" class="img-fluid" />
                     </div>
                     <div class="statnumb counter-number">
                        <span class="counter">250</span><span>+</span>
                        <p>Projects Done</p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-sm-6">
                  <div class="statistics">
                     <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                        <img src={worker} alt="work" class="img-fluid" />
                     </div>
                     <div class="statnumb">
                        <span class="counter">10</span><span>k</span><span>+</span>
                        <p>Hours Worked</p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-sm-6">
                  <div class="statistics mb0">
                     <div data-tilt data-tilt-max="20" data-tilt-speed="1000" class="statistics-img">
                        <img src={customerService} alt="support" class="img-fluid" />
                     </div>
                     <div class="statnumb">
                        <span class="counter">24</span><span>/</span><span class="counter">7</span>
                        <p>Support Available</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
        </div>
        );
}

export default Statistics;