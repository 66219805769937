import React from "react";
import misWhiteLogo from '../images/white-logo.png';
import misBlueLogo from '../images/blue-logo.png';
import { Link } from 'react-router-dom';
import { useFormik } from "formik";
import swal from 'sweetalert';
import { modalSchema } from "../schemas/modalSchema";
import axios from "axios";
import { useEffect, useState } from "react";



function Footer() {
  const initialValues = {
    name: "",
    email: "",
    message: "",
    mobile: "",
  }
  const [websiteSetting, setWebsiteSetting] = useState('');
  
  useEffect(() => {
    let url;
    url = `${process.env.REACT_APP_BASE_URL}websiteSetting`;
    axios.get(url,
      {
        headers:{
          'Authorization': `Basic ${process.env.REACT_APP_BASIC_AUTH}`
        }
      }).then(function (response) {
        setWebsiteSetting(response.data.data.docs[0]);
      }).catch(function (error) {
      })
  },[])

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: modalSchema,
    onSubmit: (values, action) => {
      axios({
        // Endpoint to send files
        url: `${process.env.REACT_APP_BASE_URL}contact`,
        headers: {
          'Authorization': `Basic ${process.env.REACT_APP_BASIC_AUTH}`
        },
        method: "POST",
        data: values,
      })
        // Handle the response from backend here
        .then((res) => {
          swal({
            title: "Thank you!",
            text: "We will contact you soon.",
            icon: "success",
          });
          action.resetForm();
        })
        // Catch errors if any
        .catch((err) => {
          swal({
            title: "Oops!!",
            text: "Some issue occured. Try again sometime!!",
            icon: "warning",
          });
        });
    }
  })
  return (
    <div>
      <footer>
        <div className="footer-row2">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-3 col-sm-6  ftr-brand-pp">
                <a className="navbar-brand mt30 mb25 f-dark-logo" href="#">
                  {" "}
                  <img src={misBlueLogo} alt="Logo" />
                </a>
                <a className="navbar-brand mt30 mb25 f-white-logo" href="#">
                  {" "}
                  <img src={misWhiteLogo} alt="Logo" />
                </a>
                <p>
                  {websiteSetting['description']}
                </p>
                <a href="/about" className="btn-main bg-btn3 lnk mt20">
                  Know More{" "}
                  <i className="fas fa-chevron-right fa-icon"></i>
                  <span className="circle"></span>
                </a>
              </div>
              <div className="col-lg-3 col-sm-6">
                <h5>Contact Us</h5>
                <ul className="footer-address-list ftr-details">
                  <li>
                    <span>
                      <i className="fas fa-envelope"></i>
                    </span>
                    <p>
                      Email
                      <span>

                        <a href="mailto:{websiteSetting['email']}">
                          <span>
                          {websiteSetting['email']}
                          </span>
                        </a>
                      </span>
                    </p>
                  </li>
                  <li>
                    <span>
                      <i className="fas fa-phone-alt"></i>
                    </span>
                    <p>
                      Phone
                      <span>

                        <a href="+91+{websiteSetting['mobile']}">+91 {websiteSetting['mobile']}</a>
                      </span>
                    </p>
                  </li>
                  <li>
                    <span>
                      <i className="fas fa-map-marker-alt"></i>
                    </span>
                    <p>
                      Address
                      <span>
                      {websiteSetting['address']}
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-sm-6">
                <h5>Company</h5>
                <ul className="footer-address-list link-hover">
                  <li>
                    <Link to="/" className="menu-links">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to={"/about"}>
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to={"/Price"} >
                      Price
                    </Link>
                  </li>
                  <li>
                    <Link to={"/Testimonial"}>
                      Testimonial
                    </Link>
                  </li>
                  <li>
                    <Link to={"/Portfolio"}>
                      Portfolio
                    </Link>
                  </li>
                  <li>
                    <Link to={"/ContactUs"}>
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
        <div className="footer-social-media-icons text-center">
          <a href="https://www.facebook.com/makeitsimple.india" target="blank">
            <i className="fab fa-facebook"></i>
          </a>
          <a href="https://twitter.com/MakeItSimpleIND" target="blank">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com/makeitsimple.india/" target="blank">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/makeitsimpleindia/" target="blank">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="https://www.facebook.com/makeitsimple.india" target="blank">
            <i className="fab fa-youtube"></i>
          </a>
        </div>
        <div className="text-center">
          <p><small>Privacy Policy / Terms & Condition. © 2022 | All Right Reserved || Design by<a href="https://www.instagram.com/tech_savvy__/" target="_blank"> Tech Savvy-The Developer</a> </small></p>
          {/* <p>
            Private Policy / Terms & Condition. © 2021{" "}
            <a
              href="https://themeforest.net/user/rajesh-doot/portfolio"
              target="blank"
            >
            </a>
          </p> */}
        </div>
      </footer>
     <div className="modal leadpopup" id="leadModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="innerbody">
                <div className="innerleft">
                  <div className="leadbtnclose">
                    {" "}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                    ></button>
                  </div>
                  <h3>The best designers are just a click away.</h3>
                  <p className="mt10">
                    Leave your details and one of our experts will contact you
                    soon
                  </p>
                  <div className="form-block mt20">
                    <form onSubmit={handleSubmit}>
                      <div className="fieldsets row">
                        <div className="col-md-12 form-group floating-label mt-4">
                          <input
                            type="text"
                            placeholder=" "
                            required="required"
                            className="floating-input mb-0"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label>Full Name*</label>
                          {errors.name && touched.name ? (<p className="form-error text-danger small mt-0">{errors.name}</p>) : null}
                        </div>
                        <div className="col-md-12 form-group floating-label mt-4">
                          <input
                            type="email"
                            placeholder=" "
                            required="required"
                            className="floating-input mb-0"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label>Email Address*</label>
                          {errors.email && touched.email ? (<p className="form-error text-danger small mt-0">{errors.email}</p>) : null}
                        </div>
                      </div>
                      <div className="fieldsets row">
                        <div className="col-md-12 form-group floating-label mt-4">
                          <input
                            type="tel"
                            placeholder=" "
                            required="required"
                            className="floating-input mb-0"
                            name="mobile"
                            value={values.mobile}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label>Mobile Number*</label>
                          {errors.mobile && touched.mobile ? (<p className="form-error text-danger small mt-0">{errors.mobile}</p>) : null}
                        </div>
                      </div>
                      <div className="fieldsets mt20">
                        {" "}
                        <button
                          type="submit"
                          name="submit"
                          className="lnk btn-main bg-btn"
                        >
                          Submit{" "}
                          <i className="fas fa-chevron-right fa-icon"></i>
                          <span className="circle"></span>
                        </button>{" "}
                      </div>
                      <p className="trm">
                        <i className="fas fa-lock"></i>We hate spam, and we
                        respect your privacy.
                      </p>
                    </form>
                  </div>
                </div>
                <div
                  class="innerright"
                  style={{
                    backgroundImage: "url(" + "../images/service/mockup-app.jpg" + ")",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div> 
     
    </div>
  );
}

export default Footer;
