import React from "react";
import icon1 from "../images/icons/icon-1.png";
import icon2 from "../images/icons/icon-2.png";
import icon3 from "../images/icons/icon-3.png";
import icon4 from "../images/icons/icon-4.png";
import icon5 from "../images/icons/icon-5.png";
import icon6 from "../images/icons/icon-6.png";
import icon7 from "../images/icons/icon-7.png";
import icon8 from "../images/icons/icon-8.png";
import icon9 from "../images/icons/icon-9.png";
import icon10 from "../images/icons/icon-10.png";
import icon11 from "../images/icons/icon-11.png";
import icon12 from "../images/icons/icon-12.png";



function WorkCategory() {

    return (
        <div>
            
            <section class="work-category pad-tb">
         <div class="container">
            <div class="row">
               <div class="col-lg-4 v-center">
                  <div class="common-heading text-l">
                     <span>Industries we work for</span>
                     <h2>Helping Businesses in All Domains</h2>
                     <p>Successfully delivered digital products Our area of specialism? Our specialty is that we do not specialize in a single or limited field; instead, we work in a variety of sectors based on your interests.</p>
                  </div>
               </div>
               <div class="col-lg-8">
                  <div class="work-card-set">
                     <div  class="icon-set wow fadeIn" data-wow-delay=".2s">
                        <div class="work-card cd1">
                           <div class="icon-bg"><img src={icon1} alt="Industries" /></div>
                           <p>Social media </p>
                        </div>
                     </div>
                     <div  class="icon-set wow fadeIn" data-wow-delay=".4s">
                        <div class="work-card cd2">
                           <div class="icon-bg"><img src={icon2} alt="Industries" /></div>
                           <p>Financial</p>
                        </div>
                     </div>
                     <div class="icon-set wow fadeIn" data-wow-delay=".6s">
                        <div class="work-card cd3">
                           <div class="icon-bg"><img src={icon3} alt="Industries" /></div>
                           <p>Economics</p>
                        </div>
                     </div>
                     <div class="icon-set wow fadeIn" data-wow-delay=".8s">
                        <div class="work-card cd4">
                           <div class="icon-bg"><img src={icon4} alt="Industries" /></div>
                           <p>Health Care</p>
                        </div>
                     </div>
                     <div class="icon-set wow fadeIn" data-wow-delay="1s">
                        <div class="work-card cd5">
                           <div class="icon-bg"><img src={icon5} alt="Industries" /></div>
                           <p>Food Services</p>
                        </div>
                     </div>
                     <div class="icon-set wow fadeIn" data-wow-delay="1.2s">
                        <div class="work-card cd6">
                           <div class="icon-bg"><img src={icon6} alt="Industries" /></div>
                           <p>Small Business</p>
                        </div>
                     </div>
                     <div class="icon-set wow fadeIn" data-wow-delay="1.4s">
                        <div class="work-card cd7">
                           <div class="icon-bg"><img src={icon7} alt="Industries" /></div>
                           <p>Retail</p>
                        </div>
                     </div>
                     <div class="icon-set wow fadeIn" data-wow-delay="1.6s">
                        <div class="work-card cd8">
                           <div class="icon-bg"><img src={icon8} alt="Industries" /></div>
                           <p>Venture Capitals</p>
                        </div>
                     </div>
                     <div class="icon-set wow fadeIn" data-wow-delay="1.8s">
                        <div class="work-card cd9">
                           <div class="icon-bg"><img src={icon9} alt="Industries" /></div>
                           <p>Health Service</p>
                        </div>
                     </div>
                     <div class="icon-set wow fadeIn" data-wow-delay="2s">
                        <div class="work-card cd10">
                           <div class="icon-bg"><img src={icon10} alt="Industries" /></div>
                           <p>Event & Ticket</p>
                        </div>
                     </div>
                     <div class="icon-set wow fadeIn" data-wow-delay="2.2s">
                        <div class="work-card cd11">
                           <div class="icon-bg"><img src={icon11} alt="Industries" /></div>
                           <p>Restaurant Service</p>
                        </div>
                     </div>
                     <div class="icon-set wow fadeIn" data-wow-delay="2.4s">
                        <div class="work-card cd12">
                           <div class="icon-bg"><img src={icon12} alt="Industries" /></div>
                           <p>Others</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
        </div>
        );
}

export default WorkCategory;