import React from "react";
import Header2 from "./Header2";

function Mission() {
  return (
    <>
      <Header2 />
      
      <section className="breadcrumb-area banner-2">
        <div className="text-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 v-center">
                <div className="bread-inner">
                  <div className="bread-menu">
                    <ul>
                      <li>
                        <a href="index-2.html">Home</a>
                      </li>
                      <li>
                        <a href="#">Mission and Vision</a>
                      </li>
                    </ul>
                  </div>
                  <div className="bread-title">
                    <h2>Mission and Vision</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service pad-tb">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block upset bg-shape">
                <img
                  src="images/about/mission.jpg"
                  alt="image"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Mission</span>
                <h2>Our Mission</h2>
                <p>
                  Our mission is to be the experts in branding and advertising
                  and the masters of the message. Our aim is to provide the best
                  customer results possible and deliver the WOW factor through
                  the works we do.
                </p>
                <h5 className="mt20 mb10">Mission Statement</h5>
                <ul className="list-style-">
                  <li>To provide the best service delivery possible</li>
                  <li>
                    To make sure we provide the best quality to the customer
                  </li>
                  <li>
                    To make sure the customers are satisfied with our services
                  </li>
                  <li>Efficient and hardworking professionals</li>
                  <li>100% customer support for the customers</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service pad-tb">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pr25">
                <span>Vision</span>
                <h2>Our Vision</h2>
                <p>
                  As we move towards our goal of being the Top Digital Marketing
                  Agency in India, we are aiming to upscale on a global scale.
                  We have a transparent vision to commit ourselves and we do
                  give a passionate service to our clients. We would like to
                  give our clients, the best results and above all better
                  services to profit their business for continued growth.
                </p>
                <h5 className="mt20 mb10">Vision Statement</h5>
                <ul className="list-style-">
                  <li>
                    We are aiming for our organization to grow on a global scale
                  </li>
                  <li>To be the most customer-centric marketing agency</li>
                  <li>To create a better everyday life for our clients</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="image-block upset bg-shape m-mt30 mb0">
                <img
                  src="images/about/vision.jpg"
                  alt="image"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-block bg-gradient6 pad-tb light-dark">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="common-heading ptag">
                <span>Values</span>
                <h2> Core Values</h2>
                <p className="mb30">
                  We think big and have hands in all leading technology
                  platforms to provide you wide array of services.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset link-hover">
            <div
              className="col-lg-4 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay=".2s">
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="images/icons/logo-and-branding.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Innovation</h4>
                <p>
                  Clients have the guarantee to get innovative results by our
                  team
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay=".6s">
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="images/icons/service3.svg"
                    alt="service"
                    className="img-fluid"
                    style={{minHeight:109}}
                  />
                </div>
                <h4>Teamwork</h4>
                <p>We have a dedicated team for a single client</p>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay=".4s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="images/icons/service2.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Efficiency</h4>
                <p>
                  There are highly efficient professionals who give their best
                  for our clients
                </p>
              </div>
            </div>
            {/* <div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".8s">
            <div className="s-block">
              <div className="s-card-icon">
                <img
                  src="images/icons/service4.svg"
                  alt="service"
                  className="img-fluid"
                />
              </div>
              <h4>Customer is King</h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
          </div> */}
            <div
              className="col-lg-6 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay="1s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="images/icons/service5.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Creativity</h4>
                <p>
                  Our dedicated team makes sure that creativity applies in the
                  work they do
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-sm-6 mt30 wow fadeInUp"
              data-wow-delay="1.2s"
            >
              <div className="s-block">
                <div className="s-card-icon">
                  <img
                    src="images/icons/service6.svg"
                    alt="service"
                    className="img-fluid"
                  />
                </div>
                <h4>Client Satisfaction</h4>
                <p>
                  We make sure our clients are 100% satisfied with the results
                  we provide
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Mission;
